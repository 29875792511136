<template>
  <div>
    <v-container class="pa-6">
      <p class="text-h4 mt-12">
        <v-icon class="text-h3">mdi-wechat</v-icon>绑定微信账号
      </p>
      <v-divider></v-divider>

      <v-alert text type="success" class="my-4" v-if="status == 1">
        绑定成功！
      </v-alert>
      <v-alert text type="error" class="my-4" v-if="status == -1">
        初始化失败
      </v-alert>
      <v-alert text type="warning" class="my-4" v-if="status == -2">
        请勿重复绑定！！
      </v-alert>

      <div v-if="status == 0">
        <v-card class="my-4">
          <v-card-title>1.关注测试号</v-card-title>
          <v-card-text>
            <v-img
              :src="bindInfo.subscribe_qrcode"
              max-height="250"
              max-width="250"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="blue"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>
        </v-card>

        <v-card class="my-4">
          <v-card-title
            >2.扫描二维码绑定账号
            <v-spacer></v-spacer>
            <v-btn depressed @click="initData" :loading="btn_loading"
              >重新生成</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-img :src="bindInfo.bind_qrcode" max-height="250" max-width="250">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="blue"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <p class="text-caption">二维码有效期5分钟</p>
          </v-card-text>
        </v-card>
      </div>
      <v-btn block depressed color="primary" class="my-6" large @click="return_home">
        返回首页
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "wechatBind",
  data: function () {
    return {
      status: 0,
      bindInfo: {},
      btn_loading: false,
      alert: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      wechat_bind_data: {
        timer: null,
      },
    };
  },
  mounted: function () {
    this.initData();
  },
  watch: {
    $route() {
      if (this.wechat_bind_data.timer != null) {
        clearInterval(this.wechat_bind_data.timer);
        this.wechat_bind_data.timer = null;
      }
    },
  },
  methods: {
    initData: function () {
      let _this = this;
      this.btn_loading = true;
      this.axios
        .get("/api/user/wechat/getBindQrcode")
        .then((res) => {
          this.bindInfo = res.data.data;
          this.btn_loading = false;
          this.status = res.data.status;
          //设置定时器
          if (this.status==0&&_this.wechat_bind_data.timer == null) {
            _this.wechat_bind_data.timer = setInterval(function () {
              _this.bind_wechat_query();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    bind_wechat_query: function () {
      let _this = this;
      this.axios({
        method: "post",
        url: "/api/user/wechat/bindCheck",
        data: {
          checkToken: _this.bindInfo.check_token,
        },
      })
        .then(function (res) {
          if (res.data.status < 0) {
            _this.error(_this.parseError(res));
          } else if (res.data.status == 0) {
            return;
          } else if (res.data.status == 1) {
            _this.status = 1;
          }
          clearInterval(_this.wechat_bind_data.timer);
          _this.wechat_bind_data.timer = null;
        })
        .catch(function (error) {
          _this.error(error);
        });
    },
    return_home:function(){
      if (this.wechat_bind_data.timer) {
        clearInterval(this.wechat_bind_data.timer);
        this.wechat_bind_data.timer = null;
      }
      this.$router.push("/");
    },
    success: function (text) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = text;
      this.alert.alert_type = "成功";
    },
    error: function (err) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = err;
      this.alert.alert_type = "错误";
    },
  },
};
</script>

<style></style>
